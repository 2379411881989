import styled from 'styled-components';

export const Item = styled.div`
  background-color: var(--color-bg);
  margin-bottom: 24px;
  padding: 24px 32px;
  border-radius: 6px;
  a {
    text-decoration: none;
  }
`;
export const Title = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #0064eb;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const Descr = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;
